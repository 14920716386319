* {
  font-family: 'Poppins', sans-serif;
}

a {
  text-decoration: none;
}

/* splash screen */

.intro {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: #161616;
  transition: 1s;
}

.logo-header {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%,-50%);
  color: #ffffff;
}

.logo {
  position: relative;
  display: inline-block;
  bottom: -20px;
  opacity: 0;
}

.logo.active {
  bottom: 0;
  opacity: 1;
  transition: ease-in-out 0.5s;
}

.logo.fade {
  bottom: 150px;
  opacity: 0;
  transition: ease-in-out 0.5s;
}



