
.navItems {
    position: fixed;
    display: flex;
    gap: 1rem;
    font-size: 1.5rem;
    justify-content: space-between;
    width: 100%;
}

.home {
    margin-left: 10px;
    padding: 10px;
}

.resume {
    padding: 10px;
    margin-right: 10px;
    border-radius: 10px;
    transition: all 0.25s cubic-bezier(0.645,0.045,0.355,1);
    cursor: pointer;
    justify-content: right;
}

.resume:hover {
    filter: brightness(1);
    background-color: #F8C8DC;
}

@media screen and (max-width: 390px) {

    .navItems {
        font-size: 1rem;
    }
}